import React from 'react';
import { Container, Grid } from "@material-ui/core";
import Carousel from '@brainhubeu/react-carousel';
import './index.scss'

const Testimonials = () => {
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1 className="main-title">Testimoniales</h1>
                </Grid>
                <Grid item md={12} xs={12}>
                    <Carousel
                        slidesPerPage={1}
                        dots
                        arrows
                    >
                        <div className="container-testimonial">
                            <h1 className="title">Hotel Square</h1>
                            <p className="content-text">Trabajar con Blancos DIBLAGA ha sido una gran experiencia, 
                                hemos contado con un gran proveedor, generando una gran productividad
                                 y confianza, gracias a la calidad y atención por parte de ellos. 
                                 Los recomiendo ampliamente</p>
                        </div>
                        <div className="container-testimonial">
                            <h1 className="title">Mon idée interior estudio</h1>
                            <p className="content-text">Trabajando de la mano con ellos en distintos proyectos, nos ha llevado a 
                                atender diferentes necesidades de nuestros clientes, formando una alianza 
                                estratégica y fuerte, logrando relaciones comerciales sólidas, pero lo más 
                                importante garantizando los productos y los espacios de los clientes. “100% 
                                recomiendo trabajar con Blancos DIBLAGA. </p>
                        </div>
                        <div className="container-testimonial">
                            <h1 className="title">Hotel Decameron</h1>
                            <p className="content-text">Blancos DIBLAGA ha brindado la calidad, cumpliendo en tiempos y precio, que 
                                requieren los estándares de la cadena Decameron, felicitando su excelente 
                                servicio y a su personal por su compromiso</p>
                        </div>
                        <div className="container-testimonial">
                            <h1 className="title">Hotel Paradise Village Nuevo Vallarta</h1>
                            <p className="content-text">La mejor opción en Blancos, confiamos plenamente en ellos  desde hace 16 años
                                 que nos han atendido, comprometidos con los estándares solicitados y mucho 
                                 más con el servicio personalizado, así como en tiempos de entrega que 
                                 se establecen, esto nos permite recomendarlos ampliamente</p>
                        </div>
                    </Carousel>
                </Grid>
            </Grid>
        </Container>
    );
}

export default Testimonials;