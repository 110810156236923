
import { Container, Grid } from '@material-ui/core';
import React from 'react';
import nuestraHistoria from '../../images/Nuestra.png'
import './index.scss';

const History = () => {
    return (
        <Container className="mt-2 mb-2">
        <Grid container spacing={2}
        direction="row" 
        justify="center" 
        alignItems="center"
        >
            <Grid item md={6} xs={12}>
                <img src={nuestraHistoria} alt="Nuestra historia" width="100%"/>
            </Grid>
            <Grid item md={6} xs={12}>
                <div className="container-text">
                    <p><span>La fundaci&oacute;n de la empresa DIBLAGA data del a&ntilde;o 1990 en la ciudad de Guadalajara, Jalisco, logrando desarrollar poco a poco los procesos necesarios para cumplir con las amplias expectativas de cada uno de los clientes a trav&eacute;s de los a&ntilde;os. Logrando as&iacute; crear una identidad propia con su imagen y productos, provocando que la empresa se posicionara como una de las principales empresas de blancos en la zona del pac&iacute;fico para el a&ntilde;o 2000.</span></p>
                    <p><span>Al pasar de los a&ntilde;os el crecimiento de la empresa fue constante y en especialmente en el a&ntilde;o 2010, ya que logr&oacute; consolidarse en otras zonas del pa&iacute;s gracias a la atenci&oacute;n de su personal y la calidad de sus procesos, desarrollando una gran variedad de nuevos productos y algunas alianzas para fortalecer la empresa.</span></p>
                    <p style={{ textAlign:'center' }}><em><span>Lo m&aacute;s importante de nuestra historia, es que el cliente nos acompa&ntilde;e en ella, logrando que su &eacute;xito, sea parte del nuestro.</span></em></p>
                </div>
            </Grid>
        </Grid>
    </Container>
    )
}

export default History;