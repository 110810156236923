import React, { useEffect, useState } from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { Grid, makeStyles } from '@material-ui/core';
import becquer1 from '../../images/BECQUER1.jpg';
import becquer2 from '../../images/BECQUER2.jpg';
import becquer3 from '../../images/BECQUER4.jpg';
import otani1 from '../../images/OTANI2.jpg';
import otani2 from '../../images/OTANI3.jpg';
import otani3 from '../../images/OTANI4.jpg';
import otani4 from '../../images/OTANI5.jpg';
import otani5 from '../../images/OTANI6.jpg';
import otani6 from '../../images/OTANI7.jpg';
import sadao1 from '../../images/SADAO1.jpg';
import sadao2 from '../../images/SADAO2.jpg';
import sadao3 from '../../images/SADAO3.jpg';
import sadao4 from '../../images/SADAO4.jpg';
import square1 from '../../images/square1.png';
import square2 from '../../images/square2.png';

const useStyles = makeStyles((theme) => (
    {
        heightMobile:{
            height:'auto',
            paddingBottom:'2rem',
        },
        desktop:{
            [theme.breakpoints.down('sm')]:{
                display: 'none',
            }
        },
        mobile:{
            display:'none',
            [theme.breakpoints.down('sm')]:{
                display: 'block',
            }
        },
        title:{
            color:'#003A7A',
            textTransform:'uppercase',
            fontFamily:'Futura Now Headline Extra Bold Italic',
            fontSize:'16pt'
        },
        containerImg:{
            maxWidth:'500px',
            height: '500px',
            display:'grid !important',
            '& img':{
                margin:'auto',
                '&.img-80':{
                    width: '100%'
                }
            }
        }
    }
))

const Gallery = () => {
    const [isMobile, setMobile] = useState(3);
    const classes = useStyles();
    const setting = {
        className: "center",
        centerMode: true,
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: isMobile,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
      };

      useEffect(()=>{
        setMobile(document.documentElement.clientWidth <= 769 ? 1 : 3);
    },[]);

  return (
    <Grid container>
        <Grid item md={12} xs={12}>
            <Slider {...setting}>
                <div className={classes.containerImg}>
                    <img src={becquer1} width="100%" alt="becquer"/>
                </div>
                <div className={classes.containerImg} >
                    <img src={becquer2} className="img-80" alt="becquer"/>
                </div>
                <div className={classes.containerImg}>
                    <img src={becquer3} className="img-80" alt="becquer"/>
                </div>
                <div className={classes.containerImg}>
                    <img src={otani1} width="100%" alt="becquer"/>
                </div>
                <div className={classes.containerImg} >
                    <img src={otani2} className="img-80" alt="becquer"/>
                </div>
                <div className={classes.containerImg}>
                    <img src={otani3} className="img-80" alt="becquer"/>
                </div>
                <div className={classes.containerImg}>
                    <img src={otani4} width="100%" alt="becquer"/>
                </div>
                <div className={classes.containerImg} >
                    <img src={otani5} className="img-80" alt="becquer"/>
                </div>
                <div className={classes.containerImg}>
                    <img src={otani6} className="img-80" alt="becquer"/>
                </div>
                <div className={classes.containerImg}>
                    <img src={sadao1} className="img-80" alt="becquer"/>
                </div>
                <div className={classes.containerImg}>
                    <img src={sadao2} width="100%" alt="becquer"/>
                </div>
                <div className={classes.containerImg} >
                    <img src={sadao3} className="img-80" alt="becquer"/>
                </div>
                <div className={classes.containerImg}>
                    <img src={sadao4} className="img-80" alt="becquer"/>
                </div>
                <div className={classes.containerImg} >
                    <img src={square1} className="img-80" alt="becquer"/>
                </div>
                <div className={classes.containerImg}>
                    <img src={square2} className="img-80" alt="becquer"/>
                </div>
            </Slider>
        </Grid>
    </Grid>
  );
}

export default Gallery;