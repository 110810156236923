import React from 'react';
import { Container, Grid } from "@material-ui/core";
import Cut from "../../assets/cut.svg";
import Bordado from "../../assets/bordado.svg";
import Caja from "../../assets/caja.svg";
import Costura from "../../assets/costura.svg";
import Herramienta from "../../assets/herramientas-de-coser.svg";
import Page from "../../assets/Page-1.svg";
import Pillow from "../../assets/pillow.svg";
import Tshirt from "../../assets/tshirt.svg";
import './index.scss';

const Service = () => {
    return (
        <Container>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <h1 className="main-title">Servicios</h1>
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                    <div className="container-service">
                        <div className="icon-container">
                            <Cut/>
                            <p className="title">Corte</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                    <div className="container-service">
                        <div className="icon-container">
                            <Herramienta/>
                            <p className="title">Costura</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                    <div className="container-service">
                        <div className="icon-container">
                            <Bordado/>
                            <p className="title">Bordado</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={3} sm={4} xs={6}>
                    <div className="container-service">
                        <div className="icon-container">
                            <Tshirt/>
                            <p className="title">Serigrafía</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={3} xs={6}>
                    <div className="container-service">
                        <div className="icon-container">
                            <Page/>
                            <p className="title">Doblado</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={3} xs={6}>
                    <div className="container-service">
                        <div className="icon-container">
                            <Caja/>
                            <p className="title">Empaque</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={3} xs={6}>
                    <div className="container-service">
                        <div className="icon-container">
                            <Costura/>
                            <p className="title">Capitoneado</p>
                        </div>
                    </div>
                </Grid>
                <Grid item md={3} xs={6}>
                    <div className="container-service">
                        <div className="icon-container">
                            <Pillow/>
                            <p className="title">Almohada</p>
                        </div>
                    </div>
                </Grid>

            </Grid>
        </Container>
    );
}

export default Service;