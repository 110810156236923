import * as React from "react";
import Layout from '../../layout';
import { makeStyles } from "@material-ui/core";
import { Link } from "@reach/router";
import 'react-slideshow-image/dist/styles.css';
import { Fade } from 'react-slideshow-image';
import History from "../../templates/historia";
import contacto from '../../images/contactoBanner.png';
import blancos from '../../images/blancos.png';
import preguntas from '../../images/preguntas.png';
import categorias from '../../images/categorias.png';
import proyecto from '../../images/proyecto.png';
import Service from "../../templates/servicio";
import Testimonials from "../../templates/Testimonial";
import { Grid } from "@material-ui/core";
import Gallery from "../../components/Gallery";

const useStyle = makeStyles((theme) => ({
  titleSlide:{
    position:'absolute',
    bottom:'10px',
    color:'white',
    fontWeight: 900,
    marginLeft: '2rem',
    fontSize: '32pt',
    fontFamily: 'Futura Now Headline Extra Bold Italic',
    [theme.breakpoints.down('sm')]:{
      textAlign:'center',
      fontSize:'24pt',
    },
    '& .text-red':{
      color:'#cc0a25',
      fontFamily: 'Futura Now Headline Extra Bold Italic'
    },
    '& .text-blue':{
      color:'#003a7a',
      fontFamily: 'Futura Now Headline Extra Bold Italic'
    }
  },

  iframe:{
    height:800,
    [theme.breakpoints.down('sm')]:{
      height:400,
    }
  }

}))

// markup
const IndexPage = () => {
  const classes = useStyle();

  return (
    <Layout appBar="fixed" footer>
      <div>
        <Fade easing="ease" autoplay={false}>
          <div className="each-slide" style={{ overflow:'hidden' }}>
            <Link to="/">
              <div className="carousel-main" style={{'backgroundImage': `url(${blancos})`, height:'100vh', backgroundPosition:'center', backgroundSize:'cover'}}>
                <h1 className={classes.titleSlide}><span className="text-red">EN BLANCOS</span> <br/><span className="text-blue">TU MEJOR OPCIÓN</span></h1>
              </div>
            </Link>
          </div>
          <div className="each-slide" style={{ overflow:'hidden' }}>
            <Link to="/contacto">
              <div className="carousel-main" style={{'backgroundImage': `url(${contacto})`, height:'100vh', backgroundPosition:'center', backgroundSize:'cover'}}>
                <h1 className={classes.titleSlide}>CONTACTO</h1>
              </div>
            </Link>
          </div>
          <div className="each-slide" style={{ overflow:'hidden' }}>
            <Link to="/categorias">
              <div className="carousel-main" style={{'backgroundImage': `url(${categorias})`, height:'100vh', backgroundPosition:'center', backgroundSize:'cover'}}>
                <h1 className={classes.titleSlide}>CATEGORÍAS</h1>
              </div>
            </Link>
          </div>
          <div className="each-slide" style={{ overflow:'hidden' }}>
            <Link to="/proyectos">
              <div className="carousel-main" style={{'backgroundImage': `url(${proyecto})`, height:'100vh', backgroundPosition:'center', backgroundSize:'cover'}}>
                <h1 className={classes.titleSlide}><span className="text-blue">PROYECTOS</span></h1>
              </div>
            </Link>
          </div>
          <div className="each-slide" style={{ overflow:'hidden' }}>
            <Link to="/faq">
              <div className="carousel-main" style={{'backgroundImage': `url(${preguntas})`, height:'100vh', backgroundPosition:'center', backgroundSize:'cover'}}>
                <h1 className={classes.titleSlide}>PREGUNTAS <br/> FRECUENTES</h1>
              </div>
            </Link>
          </div>
        </Fade>
      </div>
      <Grid container>
        <Grid item xs={12} sm={12} md={12}>
          <iframe src="https://www.youtube.com/embed/2nfLPVL56Dk?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com" width="100%" className={classes.iframe}></iframe>
        </Grid>
      </Grid>
      <History/>
      <Service/>
      <Testimonials/>
      <Gallery/>
    </Layout>
  )
}

export default IndexPage
